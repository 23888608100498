<template>
  <div>
    <div class="inner-head" v-bind:style="{ backgroundImage: 'url(' + this.headerImage + ')' }">
      <h1 v-if="this.data.title">{{ this.data.title }}</h1>
    </div>
    <div id="inner-content" class="container inner mt-5">
      <div v-html="this.data.content"></div>
      <component v-if="this.moduleId > 0" :is="optionComponent" :items="this.data.module"/>
    </div>
    <div class="container mt-5 mb-5" v-if="this.data.related">
      <QA :items="this.data.qa"/>
    </div>
    <div class="container mt-5 mb-5" v-if="this.data.related">
      <RelatedContents :items="this.data.related"/>
    </div>
    <section class="mt-5">
      <RecallForm />
    </section>
    <AddThis
        publicId="ra-61654e2607d04b3a"
        :data-url="this.domain"
        :data-title="this.data.metaTitle"
        :data-description="this.data.description"
        :data-media="this.data.ogImage"
        :async="true"
    />
  </div>
</template>

<script>
import {SITE_TITLE} from "@/config";
import CmsApiService from "@/services/CmsApiService";
import RecallForm from "@/components/partials/RecallForm";
import RelatedContents from "@/components/partials/RelatedContents";
import QA from "@/components/partials/QA";
import AddThis from 'vue-simple-addthis-share'

export default {
  name: "Content",
  components: {
    RelatedContents,
    RecallForm,
    QA,
    AddThis
  },
  data() {
    return {
      data: "",
      metaTitle: SITE_TITLE,
      siteTitle: "",
      component: null,
      moduleId: 0,
      headerImage: "",
      ogImage: "/assets/img/family-at-home.png",
      domain: ""
    }
  },
  metaInfo() {
    const mTitle = this.metaTitle;
    const mDescription = this.data.description;
    const mKeywords = this.data.keywords;
    return {
      title: mTitle,
      meta: [
        {name: 'description', content: mDescription},
        {name: 'keywords', content: mKeywords},
        {property: 'og:title', content: mTitle},
        {property: 'og:description', content: mDescription},
        {property: 'og:image', content: this.ogImage},
        {property: 'og:url', content: this.domain},
      ]
    }
  },
  mounted() {
    this.getData();
  },
  "watch": {
    $route() {
      this.getData();
    },

  },
  methods: {
    async getData() {
      this.domain=window.location.href;
      await CmsApiService.getContent(this.$route.params.slug)
          .then(response => {
            if (response) {
              this.data = response.data;
              this.metaTitle = response.data.siteTitle;
              this.moduleId = response.data.moduleId;
              if(response.data.headerImage) {
                this.headerImage = response.data.headerImage;
              }
              else{
                this.headerImage="/assets/img/inner_head.jpg";
              }
              if(response.data.ogImage) {
                this.ogImage = response.data.ogImage;
              }
            }
          });
    }
  },
  beforeCreate() {
    document.body.className = 'inner';
  },
  computed: {
    optionComponent() {
      if (this.moduleId === 0) {
        return null
      }
      return () => import(`@/components/partials/modules/Module${this.moduleId}.vue`);
    }
  }
}
</script>

<style scoped>
.inner-head{
  height: 285px;
}
</style>