import BaseApiService from "@/services/BaseApiService";
import {API_BASE_URL} from "@/config";

class TemplateApiService extends BaseApiService {

    async getNav() {
        try {
            const responseData = await this.get(API_BASE_URL + 'menu');
            return await responseData;
        } catch (e) {
            return this.handleErrors(e);
        }
    }

    async getSiteConfig(key = '') {
        try {
            if (key) {
                key = '/' + key;
            }
            const responseData = await this.get(API_BASE_URL + 'config' + key);
            return await responseData;
        } catch (e) {
            return this.handleErrors(e);
        }
    }

}

export default new TemplateApiService();
