<template>

  <div class="testimonials">
    <h2 class="mb-2 text-center">Rólunk mondták</h2>
    <div v-if="this.items.length > 0">
      <carousel :autoplay="true" :items="1" :loop="true">
        <div v-for="(item, index) in (this.items)" :key="index">
          <div class="card text-center">
            <div class="testimonial-image-wrapper">
              <img src="assets/img/quote.png" class="quote"/>
              <img class="card-img-top"
                   v-bind:src="item.image"
                   v-bind:alt="item.name"/>
            </div>
            <div class="card-body">
              <div class="card-text" v-html="item.content"/>
              <h5 class="card-title">{{ item.name }}</h5>
            </div>
          </div>
        </div>
      </carousel>
    </div>
  </div>
</template>

<script>

import carousel from 'vue-owl-carousel';
import CmsApiService from "@/services/CmsApiService";

export default {
  name: "TestimonialsCarousel",
  components: {carousel},
  data() {
    return {
      items: "",
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await CmsApiService.getContent('home-articles/6/0')
          .then(response => {
            if (response) {
              this.items = response.data;
            }
          });
    }
  }
}
</script>

<style scoped>

</style>