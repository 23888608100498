import BaseApiService from "@/services/BaseApiService";
import {API_BASE_URL} from "@/config";

class CmsApiService extends BaseApiService {

    async getContent(url) {
        try {
            if (!url) throw Error("Invalid url!");
            const responseData = await this.get(API_BASE_URL + url);
            return await responseData;
        } catch (e) {
            return this.handleErrors(e);
        }
    }

    async getRelatedContents(type=0){
        try {
            if (!type) throw Error("Invalid url!");
            const responseData = await this.get(API_BASE_URL + 'relatedContents/' + type);
            return await responseData;
        } catch (e) {
            return this.handleErrors(e);
        }
    }

    async postSearch(expression) {
        try {
            if (!expression) throw Error("Invalid expression!");
            const responseData = await this.post(API_BASE_URL + 'search', {
                'expression': expression
            });
            return await responseData;
        } catch (e) {
            return this.handleErrors(e);
        }
    }

    async postCalculatorForm(form) {
        try {
            const responseData = await this.post(API_BASE_URL + 'calculator', form);
            return await responseData;
        } catch (e) {
            return this.handleErrors(e);
        }
    }
}

export default new CmsApiService();
