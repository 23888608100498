import BaseApiService from "@/services/BaseApiService";
import {API_BASE_URL} from "@/config";

class OvbWebService extends BaseApiService {

    async postRecallForm(form) {
        try {
            const responseData = await this.post(API_BASE_URL + 'send-recall-request', form);
            return await responseData;
        } catch (e) {
            return this.handleErrors(e);
        }
    }

    async getConsultant(hash="") {
        try {
            if (!hash) throw Error("Invalid Hash!");
            const responseData = await this.get(API_BASE_URL + 'consultant/' + hash);
            return await responseData;
        } catch (e) {
            return this.handleErrors(e);
        }
    }

}

export default new OvbWebService();
