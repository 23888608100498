<template>
  <div v-if="this.items.length > 0">
    <h2 class="mb-4">Népszerű termékek</h2>
    <div class="row mb-4">
      <div class="col-md-4" v-for="(item, index) in (this.items)" :key="index">
        <div class="card">
          <router-link :to="addHash(item.url)">
            <img v-if="item.image !== null" class="card-img-top"
                 v-bind:src="item.image"
                 v-bind:alt="item.name"/>
          </router-link>
          <div class="card-body">
            <h5 class="card-title">{{ item.name }}</h5>
            <div class="card-text" v-html="item.lead"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import moment from "moment";

export default {
  name: "RelatedContents",
  props: {
    items: {type: Array, required: true},
  },
  methods: {
    moment: function () {
      return moment();
    },
    addHash(url){
      return this.$addHashParameter(url);
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).format('YYYY.MM.DD');
    }
  }
}
</script>

<style scoped>

</style>