<template>
  <div id="calculator-wrapper">
    <div class="calculator-body">
      <h3 class="mb-5">Nézze meg az Ön számára érdekes témákat!</h3>
      <b-form ref="calculatorForm" @submit.prevent="postForm">
        <b-form-row>
          <b-form-group class="col-md-6">
            <b-row class="my-1">
              <b-col sm="5">
                <label>Családi állapot:</label>
              </b-col>
              <b-col sm="7">
                <b-form-select
                    v-model="$v.form.q1.$model"
                    name="q1"
                    :options="this.formData.q1Options"
                    class="form-control custom-select"
                    :state="validateState('q1')"
                ></b-form-select>
              </b-col>
            </b-row>
          </b-form-group>

          <b-form-group class="col-md-6">
            <b-row class="my-1">
              <b-col sm="8">
                <label>Diákhitellel rendelkezik?</label>
              </b-col>
              <b-col sm="4">
                <b-form-select
                    v-model="$v.form.q3.$model"
                    name="q3"
                    :options="this.formData.q2Options"
                    class="form-control custom-select"
                    :state="validateState('q3')"
                ></b-form-select>
              </b-col>
            </b-row>
          </b-form-group>
        </b-form-row>
        <div v-if="$v.form.q1.$model > 1">
          <b-form-row>
            <b-form-group class="col-md-6">
              <b-row class="my-1">
                <b-col sm="8">
                  <label>Gyermekek száma</label>
                </b-col>
                <b-col sm="4">
                  <b-form-select
                      v-model="$v.form.q11.$model"
                      name="q11"
                      :options="this.formData.q11Options"
                      class="form-control custom-select"
                      :state="validateState('q11')"
                  ></b-form-select>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group class="col-md-6">
              <b-row class="my-1">
                <b-col sm="8">
                  <label>Várandós?</label>
                </b-col>
                <b-col sm="4">
                  <b-form-select
                      v-model="$v.form.q12.$model"
                      name="q12"
                      :options="this.formData.q2Options"
                      class="form-control custom-select"
                      :state="validateState('q12')"
                  ></b-form-select>
                </b-col>
              </b-row>
            </b-form-group>
          </b-form-row>

          <b-form-row>
            <b-form-group class="col-md-6">
              <b-row class="my-1">
                <b-col sm="8">
                  <label>Tervezett gyermekek száma</label>
                </b-col>
                <b-col sm="4">
                  <b-form-select
                      v-model="$v.form.q13.$model"
                      name="q13"
                      :options="this.formData.q13Options"
                      class="form-control custom-select"
                      :state="validateState('q13')"
                  ></b-form-select>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group class="col-md-6">
              <b-row class="my-1">
                <b-col sm="8">
                  <label>Hölgy életkora:</label>
                </b-col>
                <b-col sm="4">
                  <b-form-select
                      v-model="$v.form.q14.$model"
                      name="q14"
                      :options="this.formData.q14Options"
                      class="form-control custom-select"
                      :state="validateState('q14')"
                  ></b-form-select>
                </b-col>
              </b-row>
            </b-form-group>
          </b-form-row>
        </div>


        <b-form-row>
          <b-form-group class="col-md-6">
            <b-row class="my-1">
              <b-col sm="8">
                <label>Jelzáloghitellel rendelkezik?</label>
              </b-col>
              <b-col sm="4">
                <b-form-select
                    v-model="$v.form.q2.$model"
                    name="q2"
                    :options="this.formData.q2Options"
                    class="form-control custom-select"
                    :state="validateState('q2')"
                ></b-form-select>
              </b-col>
            </b-row>
          </b-form-group>

          <b-form-group class="col-md-6">
            <b-row class="my-1">
              <b-col v-if="showButton">
                <b-button type="submit" class="float-md-right" style="margin-top: -6px">Mutasd a javasatokat!</b-button>
              </b-col>
            </b-row>
          </b-form-group>
        </b-form-row>
      </b-form>
    </div>

    <b-modal ref="error-modal" hide-footer title="Hiba történt!">
      <div class="d-block text-center">
        <h3>Hiba történt az űrlap beküldése során!</h3>
        <validation-error :errors="validationErrors" v-if="validationErrors"></validation-error>
      </div>
      <b-button class="mt-3" variant="outline-danger" block @click="hideModal('error')">Bezár</b-button>
    </b-modal>
  </div>
</template>

<script>
import {between, integer, required} from "vuelidate/lib/validators";
import CmsApiService from "@/services/CmsApiService";
import ValidationError from "@/components/partials/ValidationError";

export default {
  name: "Calculator",
  components: {ValidationError},
  data() {
    return {
      toggleSelect: false,
      form: {
        q1: null,
        q2: 1,
        q3: 1,
        q11: 0,
        q12: 1,
        q13: 0,
        q14: 18,
      },
      formData: {
        q1Options: [
          {value: null, text: 'Kérjük válasszon'},
          {value: 1, text: 'Egyedülálló szülő gyermekkel (25 év alatti, egy lakcímen élő)'},
          {value: 2, text: 'Gyermekkel rendelkező párok'},
          {value: 3, text: 'Gyermeket tervező párok'},
        ],
        q2Options: [
          {value: 1, text: 'Nem'},
          {value: 2, text: 'Igen'},
        ],
        q11Options: [
          {value: 0, text: '0'},
          {value: 1, text: '1'},
          {value: 2, text: '2'},
          {value: 3, text: '3 vagy több'},
        ],
        q13Options: [
          {value: 0, text: '0'},
          {value: 1, text: '1'},
          {value: 2, text: '2'},
          {value: 3, text: '3 vagy több'},
        ],
        q14Options: []
      },
      validationErrors: '',
      showButton: true,
    }
  },
  validations: {
    form: {
      q1: {
        required,
        integer,
        between: between(1, 3)
      },
      q2: {
        required,
        integer,
        between: between(1, 2)
      },
      q3: {
        required,
        integer,
        between: between(1, 2)
      },
      q11: {
        required,
        integer,
        between: between(0, 3)
      },
      q12: {
        required,
        integer,
        between: between(1, 2)
      },
      q13: {
        required,
        integer,
        between: between(0, 3)
      },
      q14: {
        required,
        integer,
        between: between(18, 99)
      }
    }
  },
  mounted() {
    this.setAgeOptions();
  },
  methods: {
    showModal(ref) {
      this.$refs[ref + "-modal"].show()
    },
    hideModal(ref) {
      this.$refs[ref + "-modal"].hide()
    },
    setAgeOptions() {
      for (let i = 18; i <= 99; i++) {
        this.formData.q14Options.push({value: i, text: i});
      }
    },
    validateState(name) {
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.$refs.calculatorForm.reset();
      this.results = null;
      this.showButton = true;
      let top = this.$refs.calculatorForm.offsetTop;
      window.scrollTo(0, top);
      this.$v.$reset();
    },
    postForm: function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return
      }

      CmsApiService.postCalculatorForm(this.form)
          .then(response => {
            if (response.data.errors) {
              this.validationErrors = response.data.errors;
              this.showModal("error");
            } else {
              localStorage.results = JSON.stringify(response.data);
              this.$router.push({path: "/javaslatok"});
            }
          });
    }
  }
}

</script>

<style scoped>

</style>