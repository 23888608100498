<template>
  <b-nav-item-dropdown v-if="children.length > 0">
    <template slot="button-content">{{ name }}</template>
    <DropdownMenuItem v-for="menu in children" :key="menu.menu_id" :name="menu.current_locale.menu_name"
                      :url="menu.current_locale.menu_url" :external="menu.menu_external" :children="menu.children"
                      :parentUrl="parentUrl + url + '/'"/>
  </b-nav-item-dropdown>

  <b-dropdown-item v-else-if="external == 1" v-bind:href="url" target="_blank">{{ name }}</b-dropdown-item>
  <b-dropdown-item v-else :to="parentUrl + this.addHashParameter(url)" class="nav-link">{{ name }}</b-dropdown-item>
</template>

<script>
export default {
  name: "DropdownMenuItem",
  props: {
    name: {type: String, required: true},
    external: {type: Number},
    url: {type: String, required: true},
    parentUrl: {type: String, required: true},
    children: {type: [Array, String], required: true}
  }
}
</script>

<style scoped>

</style>