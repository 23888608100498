<template>
  <div>
    <div class="inner-head">
      <h1>404</h1>
    </div>
    <div class="container inner mt-5">
      <h5 class="text-center">A keresett oldal nem található!</h5>
    </div>
  </div>
</template>

<script>
import {SITE_TITLE} from "@/config";

export default {
  name: "NotFound404",
  metaInfo() {
    return {
      title: "404 | "+SITE_TITLE
    }
  }
}
</script>

<style scoped>

</style>