
export default {
    install(Vue) {
        Vue.prototype.$addHashParameter = function (url){
            const hash=this.$route.query.t;
            if(hash){
                return url + '?t=' + hash;
            }
            return url;
        }
    }
};