<template>
  <div>
    <div class="inner-head" v-bind:style="{ backgroundImage: 'url(' + this.headerImage + ')' }">
      <h1>Javaslatok</h1>
    </div>
    <div class="container inner mt-5">
      <div v-if="this.items" class="mt-5">
        <div v-for="(items, key) in chunkedItems" class="row mb-4" :key="key">
          <div class="col-md-4" v-for="(item, index) in (items)" :key="index">
            <router-link :to="addHash(item.url)">
              <div class="card">
                <div class="position-relative">
                  <img src="assets/img/play.png" class="img-fluid play"/>
                  <img v-if="item.image !== null" class="card-img-top"
                       v-bind:src="item.image"
                       v-bind:alt="item.name" />
                </div>
                <div class="card-body">
                  <div class="card-date">{{ item.created_at | moment }}</div>
                  <h5 class="card-title">{{ item.name }}</h5>
                  <div class="card-text" v-html="item.lead"/>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div v-else>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";

export default {
  name: "Results",
  data(){
    return {
      items: null,
      headerImage: "/assets/img/inner_head.jpg"
    }
  },
  methods:{
    moment: function () {
      return moment();
    },
    addHash(url){
      return this.$addHashParameter(url);
    }
  },
  mounted() {
    if (localStorage.results) {
      this.items = JSON.parse(localStorage.results);
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).format('YYYY.MM.DD');
    }
  },
  computed: {
    chunkedItems() {
      return _.chunk(this.items, 3)
    }
  }
}
</script>

<style scoped>
.inner-head{
  height: 285px;
}
</style>