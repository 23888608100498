<template>
  <div class="arrange mt-4">
    <div v-if="this.items.length > 0">
      <carousel :autoplay="true" :items="1" :loop="true" :dots="false">
        <b-card no-body class="overflow-hidden" v-for="(item, index) in (this.items)" :key="index">
          <b-row no-gutters>
            <b-col md="6">
              <div class="index-image">
              <b-card-img v-if="item.image !== null" class="rounded-0"
                          v-bind:src="item.image"
                          v-bind:alt="item.name"></b-card-img>
              </div>
            </b-col>
            <b-col md="6" class="d-flex" style="background: #00427c; min-height: 400px">
              <b-card-body :title="item.name" class="align-items-center d-flex justify-content-center flex-wrap">
                <div v-html="item.content"/>
                <router-link class="button" :to="addHash(item.product)">Tudjon meg többet!</router-link>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </carousel>
    </div>
  </div>
</template>

<script>

import carousel from 'vue-owl-carousel';
import CmsApiService from "@/services/CmsApiService";

export default {
  name: "ArrangeCarousel",
  components: {carousel},
  data() {
    return {
      items: "",
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await CmsApiService.getContent('home-articles/8/3')
          .then(response => {
            if (response) {
              this.items = response.data;
            }
          });
    },
    addHash(url) {
      return this.$addHashParameter(url);
    }
  }
}
</script>

<style scoped>

</style>