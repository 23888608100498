<template>
  <div v-if="this.items.length > 0" class="mb-5 mt-5">
    <h2>Kérdések és válaszok</h2>
      <div class="accordion indicator-plus-before round-indicator" role="tablist">
        <b-card no-body class="mb-1 qa" v-for="(item, index) in (this.items)" :key="index">
          <b-card-header collapsed header-tag="header" class="p-1" role="tab">
            <b-link block v-b-toggle="'accordion-' + index" variant="info" class="card-title">
              {{ item.q }}
            </b-link>
          </b-card-header>
          <b-collapse :id="'accordion-' + index" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text v-html="item.a"/>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
  </div>
</template>

<script>
export default {
  name: "QA",
  props: {
    items: {type:  Array, required: true},
  },
}
</script>

<style scoped>

</style>