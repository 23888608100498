<template>
  <div>
    <div class="inner-head" v-bind:style="{ backgroundImage: 'url(' + this.headerImage + ')' }">
      <h1 v-if="this.data.title">{{ this.data.title }}</h1>
    </div>
    <section class="mb-5">
      <div class="container">
        <div class="row mt-3">
          <div class="col">
            <!-- main carousel -->


            <!-- calculator -->
           <Calculator/>
            <!-- claculator end -->
          </div>

        </div>
      </div>
    </section>

    <section id="basic-questions" class="mb-5">
      <div class="container">
        <BasicQuestions/>
      </div>
    </section>

    <section class="mb-5">
      <RecallForm />
    </section>

    <section id="counter" class="mb-5">
      <div class="container">
        <Counter :number="counter"/>
      </div>
    </section>

    <section class="blue pt-5 pb-5 mb-5">
      <EveryoneNeedsToKnow/>
    </section>

    <section class="mb-5">
      <div class="container">
        <div class="row">
          <div class="col-md-6 mb-4">
            <h2 class="mb-4">Miért éri meg velünk intézni?</h2>
            <HomeAccordion/>
          </div>

          <div class="col-md-6">
            <TestimonialsCarousel/>
          </div>
        </div>
        <ArrangeCarousel/>
      </div>
    </section>


    <section id="key-issues" class="mb-5">
      <div class="container">
        <KeyIssues/>
      </div>
    </section>

    <section class="blue pt-5 pb-5">
      <NeedsToKnow/>
    </section>

    <section id="home-content" class="pt-5 pb-5">
      <div class="container mb-5" v-if="this.data.content">
        <h2 class="mb-5">Rólunk</h2>
        <div v-html="this.data.content"/>
      </div>
    </section>
    <AddThis
        publicId="ra-61654e2607d04b3a"
        :data-url="this.domain"
        :data-title="this.data.metaTitle"
        :data-description="this.data.description"
        :data-media="this.data.ogImage"
        :async="true"
    />
  </div>
</template>

<script>
import {SITE_TITLE} from "@/config";
import RecallForm from "@/components/partials/RecallForm";
import BasicQuestions from "@/components/partials/BasicQuestions";
import NeedsToKnow from "@/components/partials/NeedsToKnow";
import Counter from "@/components/partials/Counter";
import HomeAccordion from "@/components/partials/HomeAccordion";
import TestimonialsCarousel from "@/components/partials/TestimonialsCarousel";
import ArrangeCarousel from "@/components/partials/ArrangeCarousel";
import CmsApiService from "@/services/CmsApiService";
import KeyIssues from "@/components/partials/KeyIssues";
import Calculator from "@/components/partials/Calculator";
import EveryoneNeedsToKnow from "@/components/partials/EveryoneNeedsToKnow";
import AddThis from 'vue-simple-addthis-share'

export default {
  name: "Home",
  components: {
    Calculator,
    RecallForm,
    BasicQuestions,
    EveryoneNeedsToKnow,
    NeedsToKnow,
    Counter,
    HomeAccordion,
    TestimonialsCarousel,
    ArrangeCarousel,
    KeyIssues,
    AddThis
  },
  data() {
    return {
      data: "",
      metaTitle: SITE_TITLE,
      siteTitle: "",
      mainCarouselItems: "",
      headerImage: "",
      ogImage: "/assets/img/family-at-home.png",
      domain: ""
    }
  },
  props: {
    counter: {type: [Number], required: true}
  },
  metaInfo() {
    const mTitle = this.metaTitle;
    const mDescription = this.data.description;
    const mKeywords = this.data.keywords;
    return {
      title: mTitle,
      meta: [
        {name: 'description', content: mDescription},
        {name: 'keywords', content: mKeywords},
        {property: 'og:title', content: mTitle},
        {property: 'og:description', content: mDescription},
        {property: 'og:image', content: this.ogImage},
        {property: 'og:url', content: this.domain},
      ]
    }
  },
  mounted() {
    this.getData();
  },
  beforeCreate() {
    document.body.className = '';
  },
  methods: {
    async getData() {
      this.domain=window.location.href;
      await CmsApiService.getContent('home')
          .then(response => {
            if (response) {
              this.data = response.data;
              this.metaTitle = response.data.siteTitle;
              if(response.data.headerImage) {
                this.headerImage = response.data.headerImage;
              }
              if(response.data.ogImage) {
                this.ogImage = response.data.ogImage;
              }
            }
          });
    }
  }
}
</script>

<style scoped>

</style>