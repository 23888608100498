<template>
  <div :data-ref="refId"></div>
</template>

<script>
export default {
  name: "EmbedReviewWidget",
  props: ['refId'],
  mounted() {
    (function (d, s, id) {
      var js; if(d.getElementById(id)) {
        return
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://cdn.trustindex.io/loader.js";
      d.getElementsByTagName("head")[0].appendChild(js);
    } (document, "script", "EmbedReviewWidgetScript"));
  }
}
</script>

<style scoped>

</style>