<template>
  <div v-if="validationErrors">
    <b-list-group class="alert alert-danger">
      <b-list-group-item v-for="(value, key) in validationErrors" v-bind:key="key">{{ value }}</b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
export default {
  name: "ValidationError",
  props: ["errors"],
  computed: {
    validationErrors(){
      let errors = Object.values(this.errors);
      errors = errors.flat();
      console.log(errors);
      return errors;
    }
  }
}
</script>

<style scoped>

</style>