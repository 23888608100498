<template>
  <div id="app">
    <header>
      <div class="container">
        <Navbar :menuItems="this.menuData.menu1"/>
      </div>
    </header>

    <router-view :counter="parseInt(this.configData.counter)"/>

    <footer class="blue">
      <div class="container">
        <h5 class="mb-4 text-white">Kapcsolat</h5>
        <div class="row">
          <div class="col-md-4">
            <div class="clearfix mb-4">
              <img src="/assets/img/footer_logo.png" class="float-left mr-2"/>
              {{ this.configData.company }}<br/>
              <span class="pre-formatted">{{ this.configData.address }}</span>
            </div>

            <div class="mb-4">
              <b>Telefon: </b> {{ this.configData.phone }}
            </div>
            <div class="mb-4">
              <b>Telefax: </b> {{ this.configData.fax }}
            </div>
            <div class="mb-4">
              <b>E-mail: </b> {{ this.configData.email }}
            </div>
          </div>
          <div class="col-md-4"></div>
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-6">

              </div>
              <div class="col-md-6">
                <ul v-if="this.menuData.menu2">
                  <li v-for="menu in (this.menuData.menu2)" :key="menu.menu_id" class="mb-4">
                    <b-link v-if="menu.menu_external ==1" v-bind:href="menu.current_locale.menu_url" target="_blank">
                      {{ menu.current_locale.menu_name }}
                    </b-link>
                    <router-link v-else :to="menu.current_locale.menu_url">
                      {{ menu.current_locale.menu_name }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="mt-2 text-right">
              <b-link href="https://www.youtube.com/user/OVBMagyarorszag" target="_blank"><i
                  class="fa fa-youtube-play fa-2x mr-2" aria-hidden="true"></i></b-link>
              <b-link href="https://www.instagram.com/ovbmagyarorszag/" target="_blank"><i
                  class="fa fa-instagram fa-2x mr-2" aria-hidden="true"></i></b-link>
              <b-link href="https://www.facebook.com/ovbhungary/" target="_blank"><i class="fa fa-facebook fa-2x mr-2"
                                                                                     aria-hidden="true"></i></b-link>
              <b-link href="https://www.linkedin.com/company/ovbmagyarorszag?originalSubdomain=hu " target="_blank"><i
                  class="fa fa-linkedin fa-2x mr-2" aria-hidden="true"></i></b-link>
            </div>
          </div>
        </div>

        <div class="row mt-4 pt-4 imp">
          <div class="col-md-6">Copyright © 2021 by {{ this.configData.company }} | All Rights Reserved</div>
          <div class="col-md-6 text-md-right text-left" v-if="this.menuData.menu3">
            <span v-for="menu in (this.menuData.menu3)" :key="menu.menu_id">
               <router-link v-if="menu.external==0" :to="addHash(menu.current_locale.menu_url)"
                            class="ml-md-3 ml-0">
                 {{ menu.current_locale.menu_name }}
                </router-link>
                <a v-else :href="menu.current_locale.menu_url" target="_blank"
                   class="ml-md-3 ml-0">
                {{ menu.current_locale.menu_name }}
              </a>
            </span>
          </div>
        </div>

      </div>
    </footer>
  </div>
</template>

<script>
import Navbar from "@/components/nav/Navbar";
import TemplateApiService from "@/services/TemplateApiService";

export default {
  name: "Template",
  components: {
    Navbar
  },
  data() {
    return {
      menuData: '',
      configData: ''
    }
  },
  mounted() {
    this.getNav();
    this.getSiteConfig();
  },
  methods: {
    async getNav() {
      await TemplateApiService.getNav()
          .then(response => {
            if (response) {
              this.menuData = response.data;
            }
          });
    },
    async getSiteConfig() {
      await TemplateApiService.getSiteConfig()
          .then(response => {
            if (response) {
              this.configData = response.data;
            }
          });
    },
    addHash(url) {
      return this.$addHashParameter(url);
    }
  }
}
</script>

<style scoped>

</style>