<template>
<div>
  <b-navbar toggleable="lg" type="light" class="bg-light" variant="info">
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-navbar-brand><router-link :to="this.$addHashParameter('/')"><img src="/assets/img/logo.png" /></router-link></b-navbar-brand>

    <b-collapse id="nav-collapse" is-nav class="ml-auto">
      <b-navbar-nav class="ml-auto">
      <MenuItem v-for="menu in menuItems" :key="menu.menu_id" :name="menu.current_locale.menu_name"
                :url="menu.current_locale.menu_url" :external="menu.menu_external" :children="menu.children" :parentUrl="'/'"/>
      <!-- Right aligned nav items -->

       <!-- <b-form inline class="my-2 my-lg-0" @submit.prevent="redirectToResults">
          <b-form-input
              v-model="$v.form.search.$model"
              type="text"
              name="search"
              :state="validateState('search')"
              maxlength="100"
              class="mr-sm-2"
          ></b-form-input>
          <b-button type="submit" variant="btn my-2 my-sm-0"><i class="fa fa-search"></i></b-button>
        </b-form>-->
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
</template>

<script>
import MenuItem from "@/components/nav/MenuItem";
import {required, minLength} from "vuelidate/lib/validators";
export default {
  name: "Navbar",
  components: {MenuItem},
  data(){
    return {
      form: {
        search: null
      }
    }
  },
  props: {
    menuItems: {required: true}
  },
  validations: {
    form: {
      search: {
        required,
        minLength: minLength(2)
      }
    }
  },
  methods: {
    validateState(name) {
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    }
  }
}
</script>