import axios from "axios";

class BaseApiService {

    setHeader(key, value) {
        axios.defaults.headers.common[key] = value;
    }

    get(url = "") {
        return axios.get(url);
    }

    post(url, params, options={}) {
        return axios.post(url, params, options);
    }

    put(url, params) {
        return axios.put(url, params);
    }

    patch(url, params) {
        return axios.patch(url, params);
    }

    delete(url) {
        return axios.delete(url);
    }

    handleErrors(e) {
        const status=e.response.status;
        if (status === 404) {
            window.location.href = '/error/404';
        }
        else if(status === 422){
            return e.response;
        }
        console.log({message: "Error:", e});
    }
}

export default BaseApiService;