<template>
  <div class="container">
    <h2 class="mb-4">Mindenkinek tudnia kell</h2>
    <div v-for="(items, key) in chunkedItems" class="row" :key="key">
      <div v-for="(item, index) in items" class="col-md-4" :key="index">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">
              {{ item.name }}</h5>
            <div class="card-text" v-html="item.content"/>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <router-link class="button" :to="this.$addHashParameter(this.url)">Mutasd mindet!</router-link>
    </div>
  </div>
</template>

<script>
import CmsApiService from "@/services/CmsApiService";
import moment from "moment";
import _ from 'lodash';

export default {
  name: "EveryoneNeedsToKnow",
  data() {
    return {
      items: "",
      url: "/kerdesek-es-valaszok",
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await CmsApiService.getContent('home-articles/3/6')
          .then(response => {
            if (response) {
              this.items = response.data;
            }
          });
    },
    moment: function () {
      return moment();
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).format('YYYY.MM.DD');
    }
  },
  computed: {
    chunkedItems() {
      return _.chunk(this.items, 3)
    }
  }
}
</script>

<style scoped>

</style>