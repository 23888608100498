import Vue from "vue";
import VueRouter from "vue-router";
import Template from "@/views/Template";
import Home from "@/components/contents/Home";
import Content from "@/components/contents/Content";
import NotFound404 from "@/components/NotFound404";
import Search from "@/components/contents/Search";
import Results from "@/components/contents/Results";

Vue.use(VueRouter);

const Router = new VueRouter({
    mode: "history",
    routes: [{
        path: "/",
        component: Template,
        children: [
            {
                path: '/error/404',
                name: 'nav.notfound404',
                component: NotFound404
            },
            {
                path: '/kereses',
                name: 'nav.search',
                component: Search
            },
            {
                path: '/javaslatok',
                name: 'nav.results',
                component: Results
            },
            {
                path: "",
                name: "nav.index",
                component: Home,
                props: true
            },
            {
                path: "/:slug(.*)",
                name: "nav.content",
                component: Content,
                props: true,
            },
        ]
    }
    ],
    scrollBehavior () {
        return { x: 0, y: 0 }
    }

});


export default Router