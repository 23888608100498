<template>
  <div>
    <div class="inner-head">
      <h1>Keresés</h1>
    </div>
    <div class="container inner mt-5">
      <div v-if="this.data.length > 0">
        <div class="m-2" v-for="item in (this.data)" :key="item.content_id">
          <h5><router-link :to="this.addHashParameter(item.current_locale.content_url)" class="nav-link">
            {{ item.current_locale.content_name }} &raquo;</router-link> </h5>
          <div v-if="item.current_locale.content_lead.length" v-html="item.current_locale.content_lead" />
          <hr/>
        </div>
      </div>
      <div v-else class="text-center">
        <h5 class="text-center">A keresett kifejezésre nincs találat!</h5>
      </div>
    </div>
  </div>
</template>

<script>
import {SITE_TITLE} from "@/config";
import CmsApiService from "@/services/CmsApiService";

export default {
  name: "Search",
  data() {
    return {
      data: "",
    }
  },
  metaInfo() {
    return {
      title: "Keresés | " + SITE_TITLE
    }
  },
  mounted() {
    this.getData();
  },
  "watch": {
    $route() {
      this.getData();
    }
  },
  methods: {
    async getData() {
      await CmsApiService.postSearch(this.$route.query.q)
          .then(response => {
            if (response) {
              this.data = response.data;
              this.metaTitle = response.data.siteTitle;
            }
          });
    }
  },
  beforeCreate() {
    document.body.className = 'inner';
  }
}
</script>

<style scoped>
.inner-head{
  height: 285px;
}
</style>